@mixin willSlideFromBottom($delay: 0) {
  will-change: opacity, transform;
  transition: opacity 0.2s, transform 0.2s;
  opacity: 0;
  animation: SlideFromBottom 1s ease forwards;
  animation-delay: #{$delay}s;
  transform-origin: left;
}

@mixin introFade($additionalDelay: 0) {
  @include willSlideFromBottom;
  animation-delay: #{$additionalDelay + 1.6}s;
}

.portfolio {
  background: $text-black;
  min-height: 100vh;
  overflow-x: hidden;
  position: relative;

  &__container {
    @include has-container-max-width;
    margin: 80px auto 50px;
    color: $white;
    text-align: center;

    section:nth-child(1) {
      .section-header {
        .section-title {
          @include introFade(0);
        }
        .section-title-shadow {
          will-change: transform, opacity;
          opacity: 0;
          animation: titleShadowIntro 3s ease forwards 1s;
        }
      }
      .project-row:first-of-type {
        @include introFade(0.25);

        .project-desc .title {
          @include introFade(0.5);
        }
        .project-desc .copy {
          @include introFade(0.75);
        }
        .project-media img {
          @include introFade(1);
        }
      }
    }
  }

  section {
    text-align: left;
    width: 100%;
    margin: 0 auto;
    padding: 10px 0;

    @include bp(xl) {
      width: 85%;
    }

    &:last-of-type {
      padding-bottom: 45px;

      @include bp(xl) {
        padding-bottom: 90px;
      }
    }

    &.bg-black {
      background: $black;
    }

    .section-header {
      position: relative;
      margin: 0 0 30px;

      @include bp(xl) {
        margin: 40px 0 30px;
      }

      .section-title {
        position: relative;
        font-size: 50px;
        font-weight: 900;
        line-height: 1;
        margin: 0;
        cursor: default;
      }

      .section-title-shadow {
        position: absolute;
        top: -30px;
        right: 0;
        font-size: 60px;
        font-weight: 900;
        line-height: 1.4;
        letter-spacing: 20px;
        text-transform: uppercase;
        color: rgba($white, 0.05);
        margin: 0;
        cursor: default;
        max-width: 400px;
        text-align: right;
      }
    }

    .section-content {
      .project-row {
        display: flex;
        flex-direction: column;
        margin: 80px 0;

        @include bp(xl) {
          flex-direction: row;
          align-items: flex-end;
          justify-content: space-evenly;
          margin: 100px 0 80px;

          &.flex-center {
            align-items: center;
          }

          &.flex-start {
            align-items: flex-start;
          }
        }

        &:first-of-type {
          margin-top: 40px;

          @include bp(xl) {
            margin-top: 40px;
          }
        }

        &.with-extra {
          margin-bottom: 20px;

          @include bp(xl) {
            margin-bottom: 20px;
          }
        }

        &.with-no-media {
          .project-desc {
            @include bp(xl) {
              margin-right: 0;
            }
          }
        }
      }

      .project-desc,
      .project-desc-extra {
        p {
          position: relative;
          font-weight: 400;
          font-size: 16px;
          line-height: 1.8;
          color: $text-light;
          margin: 10px 0 0;

          @include bp(xl) {
            font-size: 19px;
          }
        }

        a {
          position: relative;
          text-decoration: none;

          &:not(:hover) {
            background-color: rgb(159, 140, 255);
            background-image: linear-gradient(
              190deg,
              rgb(221, 153, 255) 20%,
              rgb(128, 159, 255) 80%
            );
            background-size: 100%;
            background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          &::after {
            display: inline-block;
            content: ">";
            font-size: 19px;
            font-weight: 500;
            vertical-align: middle;
            padding-left: 10px;
            color: rgb(196, 153, 255);
            transition: transform 0.2s ease, opacity 0.2s;
            opacity: 0.8;
            -webkit-text-fill-color: initial;
            transition: transform 0.2s ease;
            will-change: transform;
          }

          &:hover {
            color: rgb(196, 153, 255);
            -webkit-text-fill-color: initial;

            &::after {
              transform: translateX(6px);
            }
          }
        }
      }

      .project-desc {
        cursor: default;

        @include bp(0, l) {
          order: 1;
          margin-top: 30px;
        }

        @include bp(xl) {
          margin-right: 80px;
        }

        .title {
          h3 {
            position: relative;
            font-size: 18px;
            font-weight: 600;
            line-height: 1.4;
            margin: 0;
            width: fit-content;
            overflow: hidden;

            span {
              opacity: 0;
            }

            span[role="img"] {
              opacity: 0;
              transform: translateX(-100%); // animation prep
            }

            span:not([role="img"]) {
              font-size: 20px;
              background-color: $white;
              background-image: linear-gradient(
                90deg,
                rgba(217, 217, 217, 1) 0%,
                rgba(117, 117, 117, 1) 80%
              );
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              padding-right: 15px;
              transform: translateX(-100%); // animation prep
            }

            // Secondary title (stagger)
            + h3 {
              font-weight: 400;
              span {
                animation-delay: 0.25s !important; // Start SlideTitleFromLeft after main title
              }
            }

            @include bp(xl) {
              font-size: 30px;
              span {
                font-size: 30px;
              }
            }
          }

          &.is-active {
            @mixin WillSlideTitleFromLeft {
              display: inline-block;
              will-change: opacity, transform;
              transition: opacity 0.2s, transform 0.2s;
              opacity: 0;
              transform: translateX(-100%);
              animation: SlideTitleFromLeft 0.75s ease forwards;
            }

            // Main title
            h3 {
              span:not([role="img"]) {
                @include WillSlideTitleFromLeft;
              }

              // Emoji
              span[role="img"] {
                @include WillSlideTitleFromLeft;
                animation-delay: 0.7s !important;
                animation-duration: 0.4s !important;
              }
            }
          }
        }

        .copy {
          position: relative;

          // Separator bar
          &::before {
            content: "";
            position: absolute;
            top: -25px;
            left: 0;
            border-top: 1px solid rgb(217, 217, 217);
            width: 0%;
            opacity: 0;
            transition: width 1s ease 0.4s !important;
            transform-origin: left;
          }

          p {
            opacity: 0;
            margin: 50px 0 0;
            background-color: $text-light;
            background-image: linear-gradient(146deg, #d8d8d8 0%, #858484 80%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          p a {
            opacity: 0;
            transition: opacity $transition-speed;
            transition-delay: 1s;
            will-change: opacity;
          }

          &.is-active {
            &::before {
              @include willSlideFromBottom(0.35);
              will-change: width, opacity, transform;
              width: 100%;
            }

            p {
              @include willSlideFromBottom(0.7);
              color: $text-light;
            }

            p a {
              @include willSlideFromBottom(1.1);
            }
          }
        }
      }

      .project-desc-extra {
        display: flex;

        @include bp(xl) {
          justify-content: flex-end;

          p {
            max-width: 560px;
          }
        }
      }

      .project-media {
        z-index: $z-index-body + 1;
        box-shadow: 0px 1px 10px rgba($black, 0.3);

        img,
        video {
          border-radius: 5px;
          z-index: $z-index-body + 1;

          @include bp(0, l) {
            width: 100%;
            height: auto;
          }
        }

        &.lumos-gradient {
          position: relative;

          // Video colour gradient
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: calc(100% - 5px);
            background: linear-gradient(135deg, #00d18a, #33a4fe);
            opacity: 0.26;
            border-radius: 5px;
            z-index: $z-index-body + 2;
          }
          &::after {
            content: "";
            position: absolute;
            bottom: 15px;
            right: 15px;
            height: 50px;
            width: 170px;
            background: url("../../images/lumos.png") no-repeat center / contain;
            z-index: $z-index-body + 3;
            filter: drop-shadow(0px 0px 10px rgba($black, 1));
            @include bp(l) {
              height: 60px;
              width: 200px;
            }
          }
        }
      }

      .project-media + .project-desc {
        @include bp(xl) {
          margin-left: 80px;
          margin-right: 0;
        }
      }
    }
  }

  .ig-insights {
    margin-top: 30px;
    margin-bottom: 10px;

    @include bp(xl) {
      margin-top: 80px;
      margin-bottom: 35px;
    }

    dl {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      cursor: default;

      dt {
        margin: 0 0 6px;
        font-size: 15px;
        font-weight: 700;
        filter: brightness(1.2) contrast(0.3) grayscale(0.3);
        @include bp(l) {
          font-size: 18px;
        }
      }
      dd {
        margin: 0;
        font-size: 30px;
        font-weight: 300;
        @include bp(l) {
          font-size: 45px;
        }
      }
      div {
        margin-right: 40px;
        @include bp(l) {
          margin-right: 80px;
        }
        &:nth-of-type(1) > * {
          color: #6cc4f3;
        }
        &:nth-of-type(2) > * {
          color: #9e89d9;
        }
        &:nth-of-type(3) > * {
          color: #9e43de;
        }
      }
    }
  }

  .js-fade {
    opacity: 0;
    &.is-active {
      @include willSlideFromBottom(1.1);
    }
  }

  &__scrolltop {
    position: relative;
    padding-top: 50px;
    padding-bottom: 30px;
    color: $white;
    background: $black;
    text-align: center;

    @include bp(xl) {
      padding-top: 100px;
    }

    .section-title {
      font-size: 50px;
      font-weight: 900;
      line-height: 1;
      margin: -35px 0 50px 0;
    }

    .section-title-shadow {
      margin: 0 auto;
      font-size: 60px;
      font-weight: 900;
      line-height: 1.4;
      letter-spacing: 25px;
      text-transform: uppercase;
      color: rgba($white, 0.1);
      margin: 0;
      cursor: default;
      text-align: center;
    }

    .footer-text {
      margin-top: 100px;
      font-weight: 500;
      opacity: 0.7;
    }

    .chat-suggestion {
      border-radius: 35px;
      border: 1px solid #dadada;
      background: rgba($white, 0.9);
      padding: 14px 28px;
      margin: 10px;
      transition: background 0.2s ease, color 0.2s ease, border-color 0.2s ease,
        transform 0.2s ease;
      cursor: pointer;
      font-size: 15px;
      font-weight: 500;
      color: $brand-primary;
      text-align: center;
      width: 250px;
      transform: scale(0.95);

      &:hover {
        background: $white;
        transform: scale(1);
      }

      > span > span {
        margin-right: 4px;
        font-size: 22px;
        vertical-align: middle;
      }
    }
  }
}

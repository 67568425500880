// Helpers

@mixin list-reset {
  margin: 0;
  padding: 0;
  list-style: none;
}

// Container

@mixin has-container-max-width {
  // width: 100%;
  padding: 0 20px;

  @include bp(s) {
    width: #{$bp-s-min}px;
  }

  @include bp(m) {
    width: #{$bp-m-min}px;
  }

  @include bp(l) {
    width: #{$bp-l-min}px;
  }

  @include bp(xl) {
    width: #{$bp-xl-min}px;
    padding: 0 40px;
  }

  @include bp(xxl) {
    width: #{$bp-xxl-min}px;
  }
}

// Animations & Keyframes

@keyframes fadeInFromBottom {
  from {
    opacity: 0;
    transform: translateY(14px) scale(0.4);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

@keyframes fadeOutToTop {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-13px);
  }
}

@keyframes pillIntro {
  from {
    opacity: 0;
    transform: translateY(0px) scale(0.7) translateX(-10px);
  }
  to {
    opacity: 1;
  }
}

@keyframes SlideFromBottom {
  from {
    opacity: 0;
    transform: translateY(30px) scale(0.95);
  }
  to {
    opacity: 1;
  }
}

@keyframes SlideTitleFromLeft {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes titleShadowIntro {
  from {
    letter-spacing: 35px;
    opacity: 0;
    transform: translateX(100px);
  }
  to {
    letter-spacing: 20px;
    opacity: 1;
  }
}

// Fonts
$font-family-body: "Montserrat", Helvetica, sans-serif;

// Breakpoints
$bp-xxs-min: 359;
$bp-xs-min: 480;
$bp-s-min: 640;
$bp-m-min: 768;
$bp-l-min: 1024;
$bp-xl-min: 1244;
$bp-xxl-min: 1360;

// Z-index
$z-index-bg: -100;
$z-index-body: 100;
$z-index-nav: 300;
$z-index-chat: 400;
$z-index-veil: 500;

// Colours
$brand-primary: #223870;
$brand-secondary: #00acf2;
$chat-primary: #208dfa;
$chat-secondary: #838383;
$white: #ffffff;
$black: #000000;
$yellow: #fff194;
$text-black: #191919;
$text-light: #b4b4b4;
$ticker-bg: #252525;

// Vars
$transition-delay: 90ms;
$transition-speed: 200ms;
$transition-speed-slow: 450ms;

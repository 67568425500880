* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: $font-family-body;
  background-color: $text-black;
}

@import "preloader";
@import "emojiBlurb";
@import "portfolio";

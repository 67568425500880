span.Cursor.Cursor--blinking {
  color: $chat-secondary;
}

.blurb-wrapper {
  position: relative;
  z-index: $z-index-chat;
}

.blurb-sticky {
  position: fixed;
  top: 0;
  left: 0;
  height: 80px;
  width: 100%;
  padding: 2px 0;
  box-shadow: 0 2px 8px rgba($black, 0.35);
  z-index: $z-index-veil;
  transform: translateY(-150px);
  transition: transform $transition-speed ease;
  background: $white;
  will-change: transform;

  @supports (backdrop-filter: blur(10px)) or
    (--webkit-backdrop-filter: blur(10px)) {
    backdrop-filter: blur(10px);
    background: linear-gradient(90deg, $white 100px, rgba(255, 255, 255, 0.6));

    @include bp(m) {
      background: linear-gradient(90deg, $white 25vw, rgba(255, 255, 255, 0.6));
    }
  }

  .sticky-content {
    @include has-container-max-width;
    margin: 0 auto;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @include bp(0, m) {
      padding: 0 10px;
    }

    .sticky-message {
      text-align: left;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: -0.5px;

      @include bp(0, xxxs) {
        display: none;
      }

      @include bp(m) {
        font-size: 20px;
        padding: 0 10px;
      }
    }

    .sticky-contact {
      padding: 0 10px;
      margin-left: auto;
      margin-right: 10px;

      @include bp(l) {
        margin-right: 20px;
      }

      a {
        display: inline-block;
        transition: transform $transition-speed;
        margin: 0 5px;
        will-change: transform;

        &:hover {
          transform: scale(1.1);
        }

        @include bp(m) {
          margin: 0 10px;
        }
      }

      img {
        height: 30px;
        width: auto;

        @include bp(m) {
          height: 35px;
        }
      }
    }
  }

  &.is-visible {
    transform: translateY(0);
    transition-duration: $transition-speed-slow;
  }
}

.blurb {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
  background: $white;
  transition: all $transition-speed ease;
  max-height: 100vh;
  z-index: $z-index-nav;

  @include bp(m) {
    flex-direction: row;
    padding: 60px 0;
  }

  @include bp(l) {
    padding: 90px 0;
  }

  &__video {
    video {
      height: 200px;

      @include bp(m) {
        height: 300px;
      }
    }
  }

  &__info {
    text-align: center;
    max-width: 500px;
    padding: 0 20px;

    @include bp(m) {
      text-align: left;
    }

    p {
      font-size: 20px;
      font-weight: 600;
      line-height: 1.3;

      @include bp(m) {
        font-size: 30px;
      }
    }
  }

  &__text {
    transition: opacity $transition-speed-slow ease;

    &.is-hiding {
      opacity: 0;
      transition: opacity $transition-speed ease;
    }
  }

  &__chat {
    position: relative;

    @include bp(m) {
      width: fit-content;
    }

    .chat-bubbles {
      .chat-bubble {
        z-index: $z-index-chat;
        position: absolute;
        margin-top: 15px;
        padding: 17px 20px;
        width: max-content;
        height: fit-content;
        border-radius: 20px;
        color: $white;
        font-weight: 600;
        text-align: left;
        background: $chat-secondary;
        box-shadow: 0 1px 7px 0 rgba(98, 98, 98, 0.4);
        opacity: 0;
        transform-origin: center right;
        top: -110px;
        right: 10px;

        @include bp(m) {
          top: -95px;
        }

        // From suggestions
        &::after {
          content: url("../../images/chat-bubble-trail-send.svg");
          position: absolute;
          bottom: 0px;
          right: -10px;
          height: 20px;
          width: 20px;
        }

        // From Jerry
        &.receive {
          background: $chat-primary;
          top: -205px;
          left: 10px;
          max-width: 250px;
          transform-origin: center left;

          @include bp(m) {
            top: -190px;
            max-width: 360px;
          }

          &::after {
            content: url("../../images/chat-bubble-trail-receive.svg");
            bottom: 0px;
            left: -10px;
            height: 20px;
            width: 20px;
          }
        }

        &.is-showing {
          opacity: 1;
          animation-name: fadeInFromBottom;
          animation-duration: 0.4s;
          animation-timing-function: ease-out;
          animation-fill-mode: forwards;
        }

        &.is-showing.is-hiding {
          opacity: 1;
          animation-name: fadeOutToTop;
          animation-duration: 0.2s;
          animation-timing-function: ease-out;
          animation-fill-mode: forwards;
        }

        a {
          color: $white;
        }
      }
    }

    .chat-suggestion-bar {
      margin-top: 40px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;

      @include bp(m) {
        flex-direction: row;
        width: fit-content;
      }

      .chat-suggestion {
        border-radius: 35px;
        border: 1px solid #dadada;
        background: $white;
        padding: 7px 14px;
        margin: 10px;
        min-width: 70%;
        cursor: pointer;
        font-size: 15px;
        font-weight: 500;
        text-align: center;
        color: $brand-primary;
        transition: all 0.2s ease;
        will-change: transform, opacity;
        opacity: 0;
        animation: pillIntro 0.3s ease forwards;

        @for $i from 1 through 3 {
          &:nth-child(#{$i}n) {
            animation-delay: #{$i * 0.26 + 0.6}s;
          }
        }

        @include bp(m) {
          min-width: fit-content;

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;
          }
        }

        &:not(:disabled):not(:hover) > span {
          background-color: $brand-primary;
          background-image: linear-gradient(
            90deg,
            $brand-primary 30%,
            $chat-primary 70%
          );
          background-size: 100%;
          background-clip: text;
          -webkit-text-fill-color: transparent;

          > span {
            background-clip: initial;
            -webkit-text-fill-color: initial;
          }
        }

        &:not(:disabled):hover {
          background: #ffffff;
          box-shadow: 0 2px 10px 4px rgba(203, 203, 203, 0.7);
          background: $brand-primary;
          color: $white;
        }

        &:active {
          transform: scale(0.95);
        }

        &:disabled {
          color: #dadada;
          pointer-events: none;
          border-color: rgba(#dadada, 0.6);

          span[role="img"] {
            filter: grayscale(100%) opacity(0.4);
          }
        }

        // Emoji
        > span > span {
          margin-right: 4px;
          font-size: 22px;
          vertical-align: middle;
          background-color: unset;
          background-image: unset;
          background-size: unset;
          background-clip: unset;
          -webkit-text-fill-color: unset;
        }
      }
    }
  }
}

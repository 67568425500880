@keyframes wipeIn {
  0% {
    transform: translateY(15px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 0.8;
  }
}

@keyframes wipeUp {
  0% {
    opacity: 0.8;
  }

  to {
    transform: translateY(-20px);
    opacity: 0;
  }
}

.preloader {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $white;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  z-index: 1000;
  transition: transform 1000ms ease, visibility 0ms 1500ms;
  will-change: transform;
  pointer-events: none;

  &-inner {
    color: #333333;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    width: 100%;

    @include bp(m) {
      font-size: 18px;
    }

    .hint {
      will-change: transform;
      height: 25px;
      animation-name: wipeIn;
      animation-duration: 200ms;
      animation-fill-mode: forwards;
      animation-timing-function: ease-in;

      &.is-typed {
        animation-name: wipeUp;
        animation-duration: 200ms;
        animation-fill-mode: forwards;
        animation-timing-function: ease-in;
      }

      span {
        @keyframes toggle {
          from {
            color: white;
          }
          80%,
          to {
            color: inherit;
          }
        }
        animation: toggle 0.5s infinite alternate;

        &:nth-of-type(2) {
          animation-delay: 0.2s;
        }
        &:nth-child(3) {
          animation-delay: 0.35s;
        }
      }
    }

    .progress-bar-container {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .progress-bar {
      position: relative;
      margin-top: 25px;
      width: 70vw;
      height: 3px;
      background-color: lightgrey;

      div {
        position: absolute;
        top: 0;
        left: 0;
        height: 3px;
        background-color: black;
        transition: width 600ms ease-in-out;
        will-change: width;
      }
    }
  }
}

.app {
  position: relative;
  &.is-loading {
    overflow: hidden;
    height: 100vh;

    .content {
      display: none;
    }
  }

  // Slides up preloader to hide
  &:not(.is-loading) {
    .preloader {
      transform: translateY(-100vh);
      visibility: hidden;
    }
  }
}
